// mobile navigation toggle
$(".nav-toggle").click(function(){
    event.preventDefault();
    $(".main-nav").toggleClass("open");
});

// Sticky nav
var stickyNav = $('.first-white').offset().top;
$(window).scroll(function () {
    if ($(window).scrollTop() > stickyNav) {
        $('nav').addClass('sticky');
        $('.nav-logo').css("display", "none");
    } else {
        $('nav').removeClass('sticky');
        $('.nav-logo').css("display", "block");
    }
});

// scroll down button
$(".scroll-down").click(function() {
    $('html, body').animate({
        scrollTop: $("#white").offset().top + 2
    }, 1000);
});

//add circle drawing animation class on scroll height
$(function() {
    var header = $(".draw-circle");
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= 250) {
            header.addClass("animate");
        } else {
            header.removeClass("animate");
        }
    });
});

// add fade in animation to on-site list on scroll height
$(function() {
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= $('#on-site').offset().top - 600) {
            delayListAnimation();
        }
    });
});

function delayListAnimation() {
    $('.animate-list').each(
        function(i, div) {
            var ms = 1;
            setTimeout(function() {
                $('li').each(
                    function(j, e) {
                        var s = 200;
                        setTimeout(function() {
                            $(e).css("visibility", "visible");
                            $(e).addClass('fadeInRight');
                        }, j * s);
                    }
                )
            }, i *  ms);
        }
    )
};

// add fade in animation to service lines on scroll height
$(function() {
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= $('.service-lines').offset().top - 575) {
            delayAnimate();
        }
    });
});


function delayAnimate() {
    $('.service-col').each(
        function(i, div) {
            var ms = 1000;
            setTimeout(function() {
                $(div).find('h4').each(
                    function(j, e) {
                        var s = 333;
                        setTimeout(function() {
                            $(e).css("visibility", "visible");
                            $(e).addClass('fadeInUp');
                        }, j * s);
                    }
                )
            }, i *  ms);
        }
    )
};

// Number counter on scroll height
function isScrolledIntoView(elem)
{
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();
    var elemTop = $(elem).offset().top - 10;
    var elemBottom = elemTop + $(elem).height();
    return ((elemBottom >= docViewTop) && (elemTop <= docViewBottom) && (elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}
var hasithappened = false;
var hasPartnerHappened = false;
function countUp() {
    $('.stat-number').each(function (index) {
        var size = $(this).text().split(".")[1] ? $(this).text().split(".")[1].length : 0;
        $(this).prop('Counter', 0).animate({
            Counter: $(this).text()
        }, {
            duration: 1500,
            step: function (now) {
                $(this).text(parseFloat(now).toFixed(size));
            }
        });
    });
}
$(window).scroll(function() {
    if(isScrolledIntoView($('.draw-circle')))
    {
        if(hasithappened !== true) {
            countUp();
            hasithappened = true;
        }
    }
    if(isScrolledIntoView($('#partnership')))
    {
        if(hasPartnerHappened !== true) {
            countUp();
            hasPartnerHappened = true;
        }
    }
});